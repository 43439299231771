import React, { useState, useEffect } from 'react'
import styled from "@emotion/styled"
import { Link } from 'gatsby'
import { motion } from "framer-motion"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import { useMediaQuery } from 'react-responsive'
import loadable from '@loadable/component'
import { X } from "phosphor-react"
// import Cookie from "./cookie"

import "@fontsource/manrope/400.css"
import "@fontsource/manrope/500.css"
import "@fontsource/manrope/800.css"

import Footer from './footer'

import Logo from '../images/AF-logo.svg'

const Mainnav = loadable(() => import('./mainnav'))
const Homenav = loadable(() => import('./homenav'))

const Wrapper = styled.div`
font-family: "Manrope";
margin: 0 4vw;
width: 92vw;
h2 {
    font-size: 1.5rem;
    font-weight: 400;
    padding-bottom: 2.5vh;
    padding-top: 6vh;
    color: rgba(0,0,0,0.6);
    span {
        border-bottom: solid 1px rgba(0,0,0,0.5);
    }
}  
header {
    height: 10vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0);
}
nav {
    position: absolute;
    right: 4vw;
    top: 50%;
    transform: translateY(-50%);
  }
a {
  color: rgba(0,0,0,0.5);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
  &:hover {
    color: rgb(0,0,0);
    border-bottom: 1px solid rgba(0, 0, 0, 0.33);
  }
}
a.close {
  position: fixed;
  right: 1.75vw;
  top: 1.75vw;
  display: block;
  height: 48px;
  width: 48px;
  .closer {
    pointer-events: none;
    opacity: 0.5;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.33);
    }
  }
  
}
`
const Headnav = styled.header`
position: relative;
z-index: 10;
transition: all 0.3s ease;
  &[data-active='true'] {
    background-color: rgba(255,255,255,.9);
  }
`
const Id = styled.div`
position: absolute;
left: 4vw;
top: 50%;
transform: translateY(-50%);
a {
  border: 0;
  &:hover {
    border: 0;
  }
}
`
const Maincontent = styled.main`
min-height: 100vh;
padding-top: 10vh;
box-sizing: border-box;
width: 92vw;
margin: 0 auto;
`

const Layoutwfooter = ({children}) => {
    const [state, setState] = useState({
        scrolled: false,
    });
    
    useEffect(() => {
        const handleScroll = () => {
          const isScrolled = window.scrollY > 30;
          if (isScrolled !== state.scrolled) {
            setState({
              ...state,
              scrolled: !state.scrolled,
            });
          }
        };
        document.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
          document.removeEventListener('scroll', handleScroll);
        };
    });
    const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })

    return (
      <>
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
        <>
        <Wrapper>
        {modal ? (
            <Link to={closeTo} className="close"><X size={48} weight="light" className="closer"/></Link>
        ) : (
        <Headnav data-active={state.scrolled}>
            <Id><Link to='/'><Logo /></Link></Id>
            {isDesktop ? <Mainnav /> : <Homenav />}
        </Headnav>
        )}
        {/* <Cookie /> */}
        <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        duration: 0.5
                      }}
            >
        <Maincontent>
        {children}
        </Maincontent>
        </motion.div>
        </Wrapper>
        {modal ? (
          <div></div>
        ) : (
        <Footer/>
        )}
        </>
        )}
      </ModalRoutingContext.Consumer>
      </>
    )
}
export default Layoutwfooter