import React from 'react'
import { graphql} from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "@emotion/styled"

import Layoutwfooter from '../components/layoutwfooter'
import Content from '../components/content'
import Title from '../components/title'
import Seo from '../components/seo'

const Textblock = styled.div`
background-color: #fff;
padding: 18px 0 0 0;
span {
    font-size: .9125rem;
    letter-spacing: 2px;
    font-weight: 800;
}
h3 {
  padding-bottom: 1vh;
}
img {
  width: 18.5vw;
  height: 18.5vw;
}
dl {
  padding-top: 2.5vh;
  display: grid;
  grid-template-columns: 20px 5fr;
  grid-auto-rows: min-content;
  vertical-align: baseline;
}
dt, dd { 
  line-height: 1.75rem;
  border-bottom: solid 1px rgba(0,0,0,0.05);
  padding: .5vh 0;
}
dt { 
  letter-spacing: .5px;
  font-size: .75rem;
  color: rgba(0,0,0,0.5);
}
`
const Titling = styled.div`
padding-bottom: 2vh;
h3 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-top: .5vh;
    font-size: .8375rem;
}
`
const Sidebar = styled.div`
img {
  width: 18.5vw;
  height: 18.5vw;
  @media (max-width: 599px) {
    width: 43vw;
    height: 43vw;
    margin-top: 2.5vh;
  }
}
dl {
  padding-top: 2.5vh;
  display: grid;
  grid-template-columns: 30px 5fr;
  grid-auto-rows: min-content;
  vertical-align: baseline;
}
dt, dd { 
  line-height: 1.75rem;
  border-bottom: solid 1px rgba(0,0,0,0.05);
  padding: .5vh 0;
}
dt { 
  letter-spacing: .5px;
  font-size: .75rem;
  color: rgba(0,0,0,0.5);
}
`

const People = ({ data }) => {
    const position = data.contentfulPerson.position
    // const bio = data.contentfulPerson.bio.raw
    return (
        <>
        <Layoutwfooter>
          <Seo title={data.contentfulPerson.name} />
            { position === 'Partner' ?
            <Content>
                <Textblock>
                {data.contentfulPerson.name && <Titling><Title>{data.contentfulPerson.name}</Title><h3>{data.contentfulPerson.position}</h3></Titling>}
                {data.contentfulPerson.bio && <div>{renderRichText(data.contentfulPerson.bio)}</div>}
                </Textblock>
                <Sidebar>
                  {data.contentfulPerson.photo.gatsbyImageData && <GatsbyImage image={data.contentfulPerson.photo.gatsbyImageData} alt="image" objectFit="cover" />}
                  {data.contentfulPerson.email && 
                  <dl><dt>e:</dt><dd><a href={`mailto:${data.contentfulPerson.email}`}>{data.contentfulPerson.email}</a></dd>
                  <dt>p:</dt><dd>{data.contentfulPerson.phone}</dd></dl>
                  }
                </Sidebar>
            </Content>
            :
            <Content>
            <Textblock>
            {data.contentfulPerson.name && <Titling><Title>{data.contentfulPerson.name}</Title><h3>{data.contentfulPerson.position}</h3></Titling>}
            {data.contentfulPerson.bio && <div>{renderRichText(data.contentfulPerson.bio)}</div>}
            </Textblock>
            <Sidebar>
                  {data.contentfulPerson.photo.gatsbyImageData && <GatsbyImage image={data.contentfulPerson.photo.gatsbyImageData} alt="image" objectFit="cover" />}
                  {data.contentfulPerson.email && 
                  <dl><dt>e:</dt><dd><a href={`mailto:${data.contentfulPerson.email}`}>{data.contentfulPerson.email}</a></dd>
                  <dt>p:</dt><dd>{data.contentfulPerson.phone}</dd></dl>
                  }
            </Sidebar>
            </Content>
            }
        </Layoutwfooter>
        </>
    )
}

export const query = graphql`
query ($slug: String) {
  contentfulPerson(slug: {eq: $slug}) {
    id
    name
    position
    email
    phone
    photo {
      gatsbyImageData
    }
    slug
    bio {
      raw
    }
  }
}
`

export default People